import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Helmet as Head } from "react-helmet"
import { useUser } from "../hooks/use-user"
import SortFilter from "../components/layout/sort-filter"
import React from "react"
import TagFilters from "../components/layout/tag-filters"
import Bookmarks from "../components/layout/bookmarks"
import { useFilters } from "../hooks/use-filters"
import Feeds from "../components/layout/feeds"
import { useChannel } from "../hooks/use-channel"
import ChannelView from "../components/channel/view"
import ResponsiveLayout from "../components/layout/responsive-layout"

const SubscriptionsPage: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { channel } = useChannel("", "date")
  const { user } = useUser()

  /*
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current && filters.sort != "date") {
      firstUpdate.current = false
      updateFilters({
        sort: "date",
      })
    }
  }, [filters])
*/
  useEffect(() => {
    if (user) {
      updateFilters({
        channel_ids: user.subscriptions.map(channel => channel.id),
        sort: "date",
      })
    }
  }, [user?.id])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: <Feeds initialChannelIds={[]} />,
          sidebar: (
            <>
              <SortFilter />
              <ChannelView channel={channel} onLike={undefined} />
              <TagFilters />
              <Bookmarks />
            </>
          ),
        }}
      </ResponsiveLayout>
    </>
  )
}
export default SubscriptionsPage
